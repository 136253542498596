// import base bootstrap css file
@import '~bootstrap/scss/bootstrap.scss';

// styling for chamber display
.chamber-row {
  @extend .row;

  .title {
    @extend .col-2;
  }

  .digit {
    @extend .col;
    @extend .text-right;
  }
}

// remove spinners from numerical inputs
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}